
import { mapState, storeToRefs } from 'pinia'
import { usePromoCodeStore } from '~/stores/promocode'
import { useLotteryStore } from '~/stores/lottery'
import {
  discountCalculate,
  generateItemListName,
  labelByChances,
  orderBy,
} from '@/utilities'
import { useDataLayerStore } from '~/stores/dataLayer'
import { useMarketStore } from '@/stores/market'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'MPProductItem',
  props: {
    addFirstOffer: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {},
    },
    indexProduct: {
      type: Number,
      default: null,
    },
    listName: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: '',
    },
    customView: {
      type: String,
      default: '',
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const dataLayerStore = useDataLayerStore()
    const uiStore = useUiStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      resolution,
      dataLayerStore,
    }
  },
  data() {
    return {
      sizeString: 43,
      showSeller: true,
    }
  },
  computed: {
    ...mapState(usePromoCodeStore, ['subscribe_premium_promo_cod']),
    ...mapState(useLotteryStore, ['isLottery']),
    loyaltyBonus() {
      return this.product?.loyalty_cashback?.cashback_percent
    },
    hasLoyaltyCashBack() {
      const hasCashback = this.loyaltyBonus
      let inSideDate = false
      const currentDate = new Date()
      const minDate = new Date(this.product?.loyalty_cashback?.started_at)
      const maxDate = new Date(this.product?.loyalty_cashback?.finished_at)

      if (currentDate > minDate && currentDate < maxDate) {
        inSideDate = true
      }
      return hasCashback && inSideDate
    },
    // In new catalog api we don't show sellers
    // because product initially has only default merchant info
    // and others in offers
    showSellerBlock() {
      return this.product?.offers
    },
    sellerName() {
      if (this.product?.offers) {
        const offer = this.product?.offers?.find(
          offer => offer.id === this.product?.default_offer_id,
        )
        return offer?.seller_marketing_name?.name
      } else {
        return this.product?.default_offer?.seller?.name?.name
      }
    },
    setSellersName() {
      if (this.product?.offers) {
        const offers = orderBy(this.product?.offers, 'retail_price', 'asc')
        let listMerchants = String(this.sellerName)
        offers.forEach(item => {
          if (item.seller_marketing_name?.name !== this.sellerName)
            listMerchants = `${listMerchants}, ${item?.seller_marketing_name?.name}`
        })
        return listMerchants
      } else {
        return this.sellerName
      }
    },
    productRating() {
      return this.product?.ratings?.rating_value || 0
    },
    productReviewsCount() {
      return this.product?.ratings?.session_count || 0
    },
    link() {
      return this.localePath({
        name: 'product-slug',
        params: {
          slug: `${this.product?.id || this.product?.product_id}-${
            this.product?.slugged_name
          }`,
        },
        query: {
          from_search_list: this.$route.query?.from_search,
          item_list_name: generateItemListName({
            route: this.$route,
            list_name: this.listName,
            query: this.$route?.query?.query,
            choice: this.$route?.query?.choice,
          }),
          product_index: this.indexProduct,
        },
      })
    },
    image() {
      return (
        this.product?.img_url_thumbnail ||
        this.product?.img_url_original ||
        this.product?.image_url
      )
    },
    alt() {
      return this.product?.name || ''
    },
    title() {
      return `${this.$i18n.t('product_picture')} ${this.product?.name}`
    },
    discount() {
      return this.product?.discount || null
    },
    availability() {
      return this.product?.availability || ''
    },
    oldPrice() {
      let oldPrice = this.product?.old_price
      // ProductOffers
      const default_offer_id = this.product?.default_offer_id || null
      const offers = this.product?.offers || []
      let offer = {}
      if (default_offer_id) {
        offer = offers?.find(offer => offer.id === default_offer_id)
        if (offer?.old_price) {
          oldPrice = offer?.old_price
        }
      }
      // ProductOffers
      return +oldPrice
    },
    retailPrice() {
      let retailPrice = this.product?.retail_price || this.product?.price
      // ProductOffers
      const default_offer_id = this.product?.default_offer_id || null
      const offers = this.product?.offers || []
      let offer = {}
      if (default_offer_id) {
        offer = offers?.find(offer => offer.id === default_offer_id)
        if (offer?.retail_price) {
          retailPrice = offer?.retail_price
        }
      }
      // ProductOffers
      return +retailPrice || 0
    },

    productName() {
      return this.product?.name || this.product?.product_name
    },
    lotteryLabel() {
      if (this.isLottery) {
        let key = 'market_lottery_default'

        if (this.subscribe_premium_promo_cod?.id) {
          if (this.product?.market_lottery_premium) {
            key = 'market_lottery_premium'
          }
        } else {
          if (this.product?.market_lottery_default) {
            key = 'market_lottery_default'
          }
        }

        if (this.product && this.product[key]) {
          return `${this.$t(labelByChances(this.product[key]), {
            value: this.product[key],
          })}`
        }
      }

      return ''
    },
    productOfferId() {
      return this.product?.default_offer?.uuid
    },
    installmentEnabled() {
      if (this.product?.offers) {
        const offer = this.product?.offers?.find(
          offer => offer.id === this.productOfferId,
        )
        return offer?.installment_enabled || false
      }
      if (this.product?.default_offer) {
        return this.product?.default_offer?.installment_enabled
      }
      return false
    },
    installmentMonths() {
      if (this.product?.offers) {
        const offer = this.product?.offers?.find(
          offer => offer.id === this.productOfferId,
        )
        return offer?.max_installment_months || 0
      }
      if (this.product?.default_offer) {
        return this.product?.default_offer?.max_installment_months
      }
      return 0
    },

    installmentMonthsPrice() {
      if (this.product?.offers) {
        const offer = this.product?.offers?.find(
          offer => offer.id === this.productOfferId,
        )
        return offer?.retail_price
      }
      if (this.product?.default_offer) {
        return this.product?.default_offer?.retail_price
      }
      return 0
    },
    disabledAddToCard() {
      return (
        !(this.product?.status === 'out_of_stock') &&
        !this.inCart &&
        !(this.product?.avail_check && this.product?.status === 'active') &&
        !(this.product && this.product?.status === 'active')
      )
    },
    productLabels() {
      const defaultLabels = this.product?.product_labels || []
      const offerLabels = []
      if (this.product?.offers) {
        this.product?.offers.forEach(offer => {
          if (offer.product_offer_labels) {
            offerLabels.push(...offer.product_offer_labels)
          }
        })
      }
      return [...defaultLabels, ...offerLabels]
    },
  },
  watch: {
    resolution() {
      this.checkSizeWindow()
    },
  },
  mounted() {
    this.checkSizeWindow()
  },
  methods: {
    discountCalculate,
    showSellerAction() {
      if (!this.showSeller) {
        this.showSeller = true
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'bt_show_seller',
            source: 'listing',
          })
        }
      }
    },
    onClickProduct() {
      this.setSelectItem()
      if (!this.$slots.alternativeProducts) return
      try {
        this.$router.push(
          this.localePath({
            name: 'product-slug',
            params: {
              slug: `${this.product?.id || this.product?.product_id}-${
                this.product?.slugged_name
              }`,
            },
          }),
        )
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') throw error
      }
    },
    setSelectItem() {
      let indexProduct = 1
      const page =
        this.$route?.query?.page || this.marketStore.moreProductPage + 1
      if (page > 1) {
        indexProduct = this.indexProduct + 24 * (page - 1)
      } else {
        indexProduct = this.indexProduct
      }
      let self_category_id = this.marketStore.selfCategory?.data?.id
      if (this.$route?.name?.includes('categories')) {
        const categories = this.$route?.params?.slug?.split('-')
        self_category_id = categories[0]
      }
      this.marketStore.sourceSelectItem = this.source
      this.dataLayerStore.setSelectItem(
        this.product,
        {
          item_list_name: this.listName,
          index: indexProduct,
        },
        this.$route,
      )
    },
    checkSizeWindow() {
      if (typeof window !== 'undefined') {
        if (window.innerWidth === 'sm') {
          this.sizeString = 25
        } else if (this.resolution !== 'xxl') {
          this.sizeString = 37
        } else {
          this.sizeString = 43
        }
      }
    },
  },
}
